<template>
  <table-row :class="{'bg-red-100': hasError}">
    <table-data-cell>
      <product-quick-action-modal
        v-if="showQuickActionLink"
        :id="item.productId"
      />
      <span
        v-else
      >
                #{{ item.productId }}
            </span>
    </table-data-cell>

    <table-data-cell>
      {{ item.name }}
    </table-data-cell>

    <table-data-cell>
      {{ item.supplierReference }}
    </table-data-cell>

    <table-data-cell>
      <ul class="list-none">
        <li
          v-for="(barcode, i) in item.barcodes"
          :key="i"
        >
          {{ barcode }}
        </li>
      </ul>
    </table-data-cell>

    <table-data-cell>
      {{ item.realStock }}
    </table-data-cell>

    <table-data-cell>
      {{ item.bundle.quantity }}x{{ item.bundle.content }}{{ item.bundle.unit }}
    </table-data-cell>

    <table-data-cell>
      <div class="flex items-center gap-x-2">
        <number-input-element
          v-if="isDraft"
          v-model="orderedBundleQuantity"
          :occupy-validation-space="false"
          class="w-20"
          small
          @blur="onQuantityBlur"
        />
        <div>
          <span v-if="!isDraft">{{ item.orderedBundleQuantity }}</span>
          x {{ item.bundleQuantity }} = {{ getTotalBundleQuantity }}
        </div>
      </div>
    </table-data-cell>

    <table-data-cell v-if="!isDraft">
      <div v-if="hasBeenTreated" class="flex justify-between">
        <div>
          Reçu:
          <span class="font-bold">
                        {{ item.receivedSellingUnitQuantity }}
                    </span>
        </div>

        <div v-if="item.treatedByManager" class="font-bold">
          [-- MANUEL --]
        </div>
      </div>
      <div v-else-if="!$isReadOnly()" class="flex items-center gap-x-2">
        <tooltip
          v-if="hasError"
          :text="getErrorMessage"
        >
          <fa-icon class="text-red-500" fixed-width icon="exclamation-triangle"/>
        </tooltip>

        <radio-button-component
          v-model="decision"
          value="none"
          @change="removeItemFromReceivedArray"
        />
        |
        <radio-button-component
          v-model="decision"
          value="received"
          @change="setItemInReceivedArray(null)"
        />
        <number-input-element
          v-model="receivedQuantity"
          :disabled="income"
          :occupy-validation-space="false"
          class="w-24"
          small
        />
        |
        <radio-button-component
          ref="notReceived"
          v-model="decision"
          label="Pas reçu"
          value="not_received"
          @change="setItemInReceivedArrayWithQuantityZero"
        />
      </div>
    </table-data-cell>

    <table-data-cell v-if="!income">
      <money-amount :amount="item.unitPrice.amount"/>
    </table-data-cell>
    <table-data-cell v-if="!income">
      <money-amount :amount="item.price.amount"/>
    </table-data-cell>
    <table-data-cell v-if="!income">
      <span v-if="item.receivedSellingUnitQuantity === null">{{ 0 | centsToEuroString }}</span>
      <span v-else>
                <money-amount :amount="getTotalReceivedAmountForItem(item)"/>
            </span>
    </table-data-cell>

    <table-data-cell
      v-if="isDraft && !$isReadOnly()"
      align="right"
    >
      <div class="flex gap-x-4">
        <supplier-order-delete-item-button :item-id="item.id"/>
      </div>
    </table-data-cell>

  </table-row>
</template>

<script>
import TableRow from "@/components/elements/tables/TableRow";
import TableDataCell from "@/components/elements/tables/TableDataCell";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement";
import ProductQuickActionModal from "@/components/pages/Products/Subcomponents/ProductQuickActionModal";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SupplierOrderDeleteItemButton
  from "@/components/pages/SupplierOrders/EditSupplierOrder/Includes/SupplierOrderDeleteItemButton";
import RadioButtonComponent from "@/components/elements/radio/RadioButtonComponent";
import Tooltip from "@/components/elements/tooltips/Tooltip";
import MoneyAmount from "@/components/global/Money/MoneyAmount";

export default {
  name: "SupplierOrderItem",
  components: {
    MoneyAmount,
    Tooltip,
    RadioButtonComponent,
    SupplierOrderDeleteItemButton,
    ProductQuickActionModal,
    NumberInputElement,
    TableDataCell,
    TableRow
  },
  data: () => ({
    receivedQuantity: 0,
    orderedBundleQuantity: 0,

    decision: "none"
  }),
  mounted() {
    if (!this.hasBeenTreated) {
      this.receivedQuantity = this.getTotalBundleQuantity;
    }

    this.orderedBundleQuantity = this.item.orderedBundleQuantity;
  },
  watch: {
    // Sync quantity set with the quantity in the store
    receivedQuantity(quantity) {
      if (this.decision === "received") {
        this.setItemInReceivedArray(quantity);
      }
    },
    // If Item just got marked as treated, unset it from the values to send.
    hasBeenTreated(bool, oldBool) {
      if (oldBool === false && bool === true) {
        this.removeItemFromReceivedArray();
      }
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    income: {
      type: Boolean,
      default: false
    },
    showQuickActionLink: {
      default: true,
    }
  },
  methods: {
    ...mapActions('supplierOrders', [
      'editItem'
    ]),
    ...mapMutations('supplierOrders', [
      'setItemReceived', 'removeItemReceived'
    ]),
    onQuantityBlur(quantity) {
      this.editItem({
        itemId: this.item.id,
        quantity: quantity
      }).catch(() => {
        this.orderedBundleQuantity = this.item.orderedBundleQuantity;
      })
    },
    getTotalReceivedAmountForItem(item) {
      return ((item.receivedSellingUnitQuantity / item.bundleQuantity) * item.unitPrice.amount);
    }
  },
  computed: {
    ...mapGetters('supplierOrders', [
      'isDraft', 'getBatchErrors'
    ]),
    hasError() {
      return this.getError !== undefined;
    },
    getError() {
      return this.getBatchErrors.find(e => e.id === this.item.id);
    },
    getErrorMessage() {
      return this.getError?.error || "";
    },
    getTotalBundleQuantity() {
      return this.item.orderedBundleQuantity * this.item.bundleQuantity;
    },
    hasBeenTreated() {
      return this.item.isTreated;
    }
  },
}
</script>

